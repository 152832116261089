import { Inject, Injectable, PLATFORM_ID, TemplateRef } from "@angular/core";
import * as _ from "lodash";
import { ActivatedRoute, Router } from "@angular/router";
import { DOCUMENT, DatePipe, DecimalPipe, isPlatformBrowser } from "@angular/common";
import { environment } from "@env/environment";

@Injectable()
export class GlobalService {
  @Inject(PLATFORM_ID) private platformId: Object;
  @Inject(DOCUMENT) private document: any;
  public CLIENT = 'marketsmojo';
  public LOGIN_USER_DETAIL: any = {};
  public LOGOUT_DETAIL: any = {};
  public LOGIN_USER_CARD_DETAIL: any = {};
  public IMG_URL = environment.MOJO_IMG_URL;
  public MF_IMG_URL = this.IMG_URL + "mf_flow/";
  public BASE_URL = "https://www.marketsmojo.com/stocks";
  public FRAPI_URL = environment.FRAPI_URL;
  public FRONTEND_URL = environment.MOJO_URL;
  public PAGE_TITLE = "";
  public DEVICETYPE = "";
  public DEVICE = "";
  public DEVICEAPPVER = "";
  public ACC_ID: any[] = [];
  toasts: any;
  SESSION_KEY = window.location.host;
  fromPageObject: any = {
    '/mojopro/mojostock': 'mojostock',
    '/mojopro/strategy/1': 'hidden-turnaround',
    '/mojopro/strategy/2': 'momentumnow-stocks',
    '/mojopro/strategy/3': 'reliable-performers',
    '/mojopro/stock-of-the-week': 'stock-of-the-week',
    '/mojopro/stock-of-the-month': 'stock-of-the-month',
    '/mojo/professional': 'screener',
    '/portfolio-plus/watchlist': 'watchlist',
    '/mojo/verdict': 'verdict',
    '/mojo/switcher': 'switcher',
    '/markets_new': 'markets',
    '/mojopro/result-corner-landing': 'earnings',
    '/mojo/resultcorner': 'resultcorner'
  }

  constructor(
    private _decimalPipe: DecimalPipe,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _datePipe: DatePipe
  ) { }

  getFromPage(pathname: any) {
    return this.fromPageObject[pathname];
  }

  getSearchUrl(stockId: number, exchange: string = ''): string {
    if (exchange === '') {
      exchange = '0';
    }
    return `/Stocks?StockId=${stockId}&Exchange=${exchange}`;
  }

  public GetuserDetails(key: any = "") {
    let data = this.LOGIN_USER_DETAIL;
    let login_response: any = {};
    if (this.getVal(data, "data.is_login")) {
      login_response = this.getVal(data, "data");
    }
    return this.getVal(login_response, key);
  }
  public paramsRemoveNull(obj: any) {
    for (let key in obj) {
      if (_.isNil(obj[key])) {
        obj[key] = "";
      }
    }
    return obj;
  }
  /*   public getUsrId(){
      return localStorage.getItem("userid");
    } */
  public getQueryParams(key: string) {
    let result = "";
    this.activatedRoute.queryParams.subscribe((params) => {
      if (this.getVal(params, key)) {
        result = this.getVal(params, key);
      }
    });
    return result;
  }
  public notPaidUserPage(page = "", rurl = "") {
    let url = environment.MOJO_URL + "mojo/login";
    if (page == "nonlogin") {
      url += "?redirect=" + rurl;
    }
    return url;
  }

  findReplace(str: any = "", key: any = "", value: any = "") {
    return _.replace(str, key, value);
  }
  userImg(obj: any, key: any = "") {
    if (this.getVal(obj, key) && this.getVal(obj, key) != null) {
      return this.getVal(obj, key);
    } else {
      return "https://i.marketsmojo.com/menu/user.png";
    }
  }
  public getVal(o: any, key: any, def: any = "") {
    if (typeof key == "undefined" || typeof o == "undefined" || key == null) {
      return def;
    } else if (typeof def == "undefined") {
      return "";
    } else {
      var temp = o;
      let key1 = key.split(".");
      for (var i in key1) {
        if (typeof temp[key1[i]] == "undefined") {
          return def;
        } else {
          temp = temp[key1[i]];
        }
      }
      return temp;
    }
  }
  public stockUrl(obj: any, sid_key: any, exc: any) {
    // let qp: any = { 'StockId': '' + this.getVal(obj, sid_key), 'Exchange': '' + exc };
    let qp: any = "?StockId=" + this.getVal(obj, sid_key) + "&Exchange=" + exc;
    return qp;
  }
  public getDirPfArrow = function (dir: any = "") {
    let class_name = "grey-ar-new";
    if (dir == 1) {
      class_name = "green-ar-new";
    } else if (dir == -1) {
      class_name = "red-ar-new";
    } else if (dir == 0) {
      class_name = "orange-ar-new";
    }
    return class_name;
  };
  public stockTradingUrl(obj: any, sid_key: any, exc: any = 0) {
    let p = "";
    if (this.getVal(obj, "call_duration")) {
      p += "&Positional" + obj.call_duration == "W" ? "M" : "";
    }
    //news.call_duration=='W'?'M':''
    let qp: any = "Stocks?StockId=" + this.getVal(obj, sid_key) + "&Exchange=" +
      exc + p;
    return qp;
  }
  public includes(obj: any = [], val: any = "") {
    return _.includes(obj, val);
  }
  public mfUrl(obj: any, sid_key: any) {
    let url = "";
    if (this.getVal(obj, "slug")) {
      url += this.getVal(obj, "slug");
    }
    let qp: any = url + "?schemeid=" + this.getVal(obj, sid_key);
    return qp;
  }
  getClassbyClr(clr: any) {
    let class_byclr = "grey-mojo";
    if (typeof clr !== "undefined" && clr !== "") {
      clr = clr.toLowerCase();
    }
    if (clr == "green") {
      class_byclr = "green-mojo";
    } else if (clr == "red") {
      class_byclr = "red-mojo";
    } else if (clr == "orange" || clr == "yellow") {
      class_byclr = "orange-mojo";
    }

    return class_byclr;
  }
  getDotDirCls(dir: any) {
    let class_byclr;
    if (dir == 1)
      class_byclr = "up-arrow-mojoN";
    else if (dir == -1)
      class_byclr = "down-arrow-mojoN";
    else if (dir == 0)
      class_byclr = "mid-arrow-mojoN";
    else
      class_byclr = "";

    return class_byclr;
  }
  getClassbyTechClr(clr: any) {
    let class_byclr;
    if (clr == "Green")
      class_byclr = "green";
    else if (clr == "Red")
      class_byclr = "red";
    else if (clr == "Orange")
      class_byclr = "orange";
    else
      class_byclr = "grey";

    return class_byclr;
  }
  getTechnicalScoreClass(techText: any) {
    var class_name = '';

    if (techText == 'Bullish')
      class_name = "bullishbg";
    else if (techText == 'Mildly Bullish')
      class_name = "mildbullishbg";
    else if (techText == 'Bearish')
      class_name = "barishhbg";
    else if (techText == 'Mildly Bearish')
      class_name = "mildbarishhbg";
    else if (techText == 'Sideways')
      class_name = "yelowbg";
    else
      class_name = "greybg";

    return class_name;
  }
  numberWithCommas = function (x: any) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };
  getDirClrDefault(dir: any, default_clr: any = "grey") {
    let class_name = default_clr;
    if (dir == 1) {
      class_name = "green";
    } else if (dir == -1) {
      class_name = "red";
    }

    return class_name;
  }
  getAppType() {
    var useragent = window.navigator.userAgent.toLowerCase();
    let r = "other";
    if (
      (useragent.indexOf("mm-app-v3.0") > -1) ||
      (useragent.indexOf("mm-app-v3.1") > -1) ||
      (useragent.indexOf("mm-app-v3.2") > -1)
    ) {
      var iOS = /ios/.test(navigator.userAgent || "");
      if (iOS == true) {
        r = "iosapp";
      } else {
        r = "other";
      }
    } else if (
      (useragent.indexOf("mm-app;vn:v3.0") > -1) ||
      (useragent.indexOf("mm-app;vn:v3.1") > -1) ||
      (useragent.indexOf("mm-app;vn:v3.2") > -1)
    ) {
      var android = /android/.test(navigator.userAgent || "");
      if (android == true) {
        r = "androidapp";
      } else {
        r = "other";
      }
    }
    return r;
  }
  getDevicep() {
    var useragent = window.navigator.userAgent.toLowerCase();
    if (useragent.indexOf("mm-app") > -1) {
      return "app";
    } else if (useragent.indexOf("mobile") > -1) {
      return "mobile";
    } else {
      return "desktop";
    }
  }
  getAppVersion() {
    var useragent = window.navigator.userAgent.toLowerCase();
    let r = "other";
    if (useragent.indexOf("mm-app-v3.0") > -1) {
      var iOS = /ios/.test(navigator.userAgent || "");
      if (iOS == true) {
        r = "iosv3.0";
      }
    } else if (
      useragent.indexOf("mm-app-v3.1") > -1 ||
      useragent.indexOf("mm-app-v3.2") > -1
    ) {
      var iOS = /ios/.test(navigator.userAgent || "");
      if (iOS == true) {
        r = "iosv3.1";
      }
    } else if (useragent.indexOf("mm-app;vn:v3.0") > -1) {
      var android = /android/.test(navigator.userAgent || "");
      if (android == true) {
        r = "androidv3.0";
      }
    } else if (
      useragent.indexOf("mm-app;vn:v3.1") > -1 ||
      useragent.indexOf("mm-app;vn:v3.2") > -1
    ) {
      var android = /android/.test(navigator.userAgent || "");
      if (android == true) {
        r = "androidv3.1";
      }
    }

    return r;
  }
  removeSpace(str: any) {
    return str.replace(/\s+/g, "");
  }
  INRConvert(input: any, decimal: any = "1.2-2") {
    let result: any = "";
    if (decimal == 0) {
      decimal = "1.0-0";
    }
    if (!isNaN(input)) {
      // First, transform the number to a string with the desired decimal places
      let formattedNumber = this._decimalPipe.transform(input, decimal, "en-IN");
      if (formattedNumber) {
        // Custom formatter for Indian numbering system
        result = this.indianNumberFormat(formattedNumber);
      }
    }
    return result;
  }
  private indianNumberFormat(value: string): string {
    // Convert the string to a number, remove commas if any
    let numberValue = parseFloat(value.replace(/,/g, ''));
    let isNegative = numberValue < 0;
    // Split the integer and decimal parts
    let parts = numberValue.toString().split(".");
    let integerPart = isNegative ? parts[0].substring(1) : parts[0]; // Remove the negative sign for processing
    let decimalPart = parts[1] ? "." + parts[1] : "";
    // Apply Indian numbering format
    let lastThree = integerPart.slice(-3);
    let otherNumbers = integerPart.slice(0, -3);
    if (otherNumbers != '') {
      lastThree = ',' + lastThree;
    }
    let formattedIntegerPart = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return (isNegative ? '-' : '') + formattedIntegerPart + decimalPart;
  }
  convertIndRs(number: any = 0, decimal: any = "1.2-2") {
    let output = '';
    if (!isNaN(number)) {
      if (number >= 1000 && number < 100000 || number <= -1000 && number > -100000) {
        output = this.INRConvert(number / 1000, decimal) + ' k';
      } else if (number >= 100000 && number < 10000000 || number <= -100000 && number > -10000000) {
        output = this.INRConvert(number / 100000, decimal) + ' lacs';
      } else if (number >= 10000000 || number <= -10000000) {
        output = this.INRConvert(number / 10000000, decimal) + ' cr';
      } else {
        output = this.INRConvert(number, decimal)
      }
    }
    return output;
  }
  getScoreColor(key = "") {
    /*  let values = {
       "Strong Buy": "#39b54a",
       "Buy": "#65c29c",
       "Hold": "#fbb03b",
       "Strong Sell": "#FF0005",
       "Sell": "#fc7765",
       "btnunactive": "#FF0005",
     }; */

    let values = {
      "Strong Buy": "#1CB993",
      "Buy": "#1CB993",
      "Hold": "#FFBA36",
      "Strong Sell": "#FA776E",
      "Sell": "#FA776E",
      "btnunactive": "#FA776E",
    };
    return this.getVal(values, key);
  }
  getMilliseconds() {
    return new Date().getMilliseconds();
  }

  getBannerTrack(card_data: any = []) {
    let bannertack: any = {};
    if (
      card_data.data.booster.web != undefined ||
      card_data.data.booster.web != null
    ) {
      bannertack["bucket_id"] = card_data.bucket;
      bannertack["banner_id"] = card_data.banner_hash;
    }
    if (
      card_data.featuredBanner != undefined || card_data.featuredBanner != null
    ) {
      if (Array.isArray(card_data.featuredBanner)) {
        card_data.featuredBanner.forEach((val: any, key: any) => {
          bannertack["s_banner_id_" + key] = val.banner_hash;
        });
      }
    }
    if (
      card_data.web_popupshow != undefined && card_data.web_popupshow == "yes"
    ) {
      bannertack["webinar_banner"] = card_data.web_popup.webinar_hash;
    }

    if (card_data.sitecard != undefined && card_data.sitecard.image != "") {
      bannertack["diwali_banner"] = card_data.sitecard.banner_hash;
    }

    if (
      card_data.sub_popupshow != undefined && card_data.sub_popupshow == "yes"
    ) {
      bannertack["subs_banner"] = card_data.sub_popup.sub_hash;
    }
    return bannertack;
  }

  transformDate(date: any) {
    return this._datePipe.transform(date, 'dd-MMM-yyyy');
  }
  getScoreTextColor(key = "") {
    let values = {
      "Strong Buy": "green",
      "Buy": "green",
      "Hold": "orange",
      "Strong Sell": "red",
      "Sell": "red"
    };
    return this.getVal(values, key);
  }
  getAnchorTraget() {
    let t = '_blank';
    if (this.getDevicep() == 'app') {
      t = '_self';
    }
    return t;
  }
  setCookie(name: any, value: any, domain?: any, days?: any) {
    if (isPlatformBrowser(this.platformId)) {
      domain = (typeof domain === 'undefined') ? ".marketsmojo.com" : domain;
      days = (typeof days === 'undefined') ? 60 : days;
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      var expires = "; expires=" + date.toUTCString();
      this.document.cookie = name + "=" + value + expires + ";domain=" + domain + "; path=/";
    }
  }
  public capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

}
