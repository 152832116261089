import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { GlobalService } from "@app/_common/global";
import { UserService } from "@services/user.service";
import { CookieService } from "ngx-cookie-service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "@env/environment.prod";
import clevertap from 'clevertap-web-sdk';
declare const Android: any;
declare var gtag: Function;
@Component({
  selector: "app-login-modal",
  templateUrl: "./login-modal.component.html",
})
export class LoginComponent implements OnInit {
  @Input() modal: any = {};
  @ViewChild('lockinModal') lockinModal: ElementRef;
  imgUrl: string = "";
  sfeedImgUrl: string = '';
  loginForm: FormGroup;
  forgotForm: FormGroup;
  registerForm: FormGroup;
  lg: any = {};
  remeberme: boolean = false;
  showPassword: boolean = false;
  package: any = "";
  submitted: boolean = false;
  forgotSubmitted: boolean = false;
  forgotEmailErr: string = "";
  forgotStep: string = "emailVerify";
  appType: string = "";
  logoutDetail: any = {};
  encodePackage: any = "";
  loginFormErr: any = { username: "", password: "" };
  isDisabled: boolean = false;

  feedtoken: any = '';
  ltexes: any = {};
  rdm_cookie: any = {};
  utm_source: any = '';
  redirectUrl: string = '/mojopro/search-home';
  @ViewChild('frmSelectProfile') formbtn: ElementRef;
  pt: any = '';
  pn: any = '';
  formType: any = 'login';
  deviceType: string = '';
  targetVal: string = '';
  register_error: any = {};
  isUserExist: boolean = false;
  isPasswordShort: boolean = false;
  isChecked: boolean = false;
  isNotChecked: boolean = false;
  is_clmm: boolean = false;
  redirectFromMarketPage: string = "";
  queryParamForMarket: any = {
    ms: { ms: 1 },
    pn: {},
  };
  mojostock: any = "";
  ms: any = '';
  n: any = '';
  isRegisterReadonly: boolean = false;

  constructor(
    private _fb: FormBuilder,
    private _global: GlobalService,
    private _userService: UserService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private cookie: CookieService,
    private modalService: NgbModal,
  ) {
    this.is_clmm = environment.ISCLMM;
    this.logoutDetail = this._global.LOGOUT_DETAIL;
    this.appType = this._global.getAppType();
    this.deviceType = this._global.getDevicep();
    this.imgUrl = this._global.IMG_URL;
    // TODO:Change this to mojofeed when pushing to live
    this.sfeedImgUrl = this._global.IMG_URL + 'mojofeeds/';
    // let userDetails = this._global.LOGIN_USER_DETAIL;
    // if (this._global.getVal(userDetails, "data.is_login") == true) {
    //   window.location.href = environment.MOJO_URL + 'mojo/mpcombo?utm_source=MM_AutoRedirect_FlashBack_MpCombo';
    // }

    if (this.deviceType == 'app') {
      this.targetVal = '_self'
    } else {
      this.targetVal = '_blank'
    }

    try {
      this.rdm_cookie = JSON.parse(this.cookie.get('_rdm'));
      if (this._global.getVal(this.rdm_cookie, 't')) {
        this.loadpageText(this._global.getVal(this.rdm_cookie, 't'));
      }
    } catch (e) {
      this.loadpageText();
    }

    this.remeberme = this.cookie.get("remember") == "1" ? true : false;
    console.log("Remember: ", this.remeberme);
    let usernameCookie = this.cookie.get("_mgt");
    let passwordCookie = this.cookie.get("_mgm");
    console.log("Login user in cookie: ", usernameCookie, passwordCookie);
    if (
      usernameCookie && passwordCookie
    ) {
      this.lg.username = atob(usernameCookie);
      this.lg.password = atob(passwordCookie);
      console.log("Login Data: ", this.lg);
    }

    this.loginForm = this._fb.group({
      email: ["", Validators.compose([Validators.required, Validators.email])],
      password: [
        "",
        Validators.compose([
          Validators.required,
        ]),
      ],
      remeberme: [''],
    });

    this.forgotForm = this._fb.group({
      email: ["", Validators.compose([Validators.required, Validators.email])],
    });

    this.registerForm = this._fb.group({
      email: ["", Validators.compose([Validators.required, Validators.email])],
      password: [
        "",
        Validators.compose([
          Validators.required,
        ]),
      ],
    });

    this._activatedRoute.queryParams.subscribe((params) => {
      this.encodePackage = this._global.getVal(params, "package");
      this.package = atob(this._global.getVal(params, "package"));
      let email = atob(this._global.getVal(params, "pe"));
      this.utm_source = this._global.getVal(params, "utm_source");
      this.pn = this._global.getVal(params, "pn")
      this.mojostock = this._global.getVal(params, "ms");
      if (this._global.getVal(params, "redirect")) {
        this.redirectUrl = this._global.getVal(params, "redirect")
        if (this._global.getVal(params, 'pt')) {
          this.pt = this._global.getVal(params, 'pt');
          this.redirectUrl = this.redirectUrl + '&pt=' + this.pt;
        }
      }
      if (email != '') {
        this.lg = {};
        this.lg.username = email;
      }
      console.log("Package: ", this.package);
      console.log('Username: ', this.lg);
      if (this.mojostock == '1') {
        this.redirectFromMarketPage = 'ms';
      } else if (this.pn) {
        this.redirectFromMarketPage = this.pn;
        this.queryParamForMarket.pn = {
          ms: this.mojostock,
          pn: this.pn,
          n: this.n,
        }
      } else {
        this.redirectFromMarketPage = 'mojofeed';
      }
    });

    this._activatedRoute.queryParams.subscribe((params) => {
      this.encodePackage = this._global.getVal(params, "package");
      this.package = atob(this._global.getVal(params, "package"));
      this.mojostock = this._global.getVal(params, "ms");
      let email = atob(this._global.getVal(params, "pe"));
      this.utm_source = this._global.getVal(params, "utm_source");
      this.pn = this._global.getVal(params, "pn");
      this.n = this._global.getVal(params, "n");
      if (this._global.getVal(params, "redirect")) {
        this.redirectUrl = this._global.getVal(params, "redirect")
        if (this._global.getVal(params, 'pt')) {
          this.pt = this._global.getVal(params, 'pt');
          this.redirectUrl = this.redirectUrl + '&pt=' + this.pt;
        }
      }
      if (email) {
        this.registerForm.controls['email'].setValue(email);
      }
      if (this.mojostock == '1') {
        this.redirectFromMarketPage = 'ms';
      } else if (this.pn) {
        this.redirectFromMarketPage = this.pn;
        this.queryParamForMarket.pn = {
          ms: this.mojostock,
          pn: this.pn,
          n: this.n,
        }
      } else {
        this.redirectFromMarketPage = 'mojofeed';
      }
    });
  }

  ngOnInit(): void {
    gtag('event', 'conversion', { 'send_to': 'AW-356785777/8iKBCK6xhc0YEPG8kKoB' });
  }

  get formVal(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  get forgotFormVal(): { [key: string]: AbstractControl } {
    return this.forgotForm.controls;
  }

  get registerFormVal(): { [key: string]: AbstractControl } {
    return this.registerForm.controls;
  }

  checkedRememberMe() {
    this.remeberme = !this.remeberme;
    if (this.remeberme) {
      this.cookie.set("remember", "0");
      this.cookie.set("_mgt", "");
      this.cookie.set("_mgm", "");
    } else {
      this.cookie.set("remember", "1");
      this.cookie.set("_mgt", btoa(this.lg.username));
      this.cookie.set("_mgm", btoa(this.lg.password));
    }
  }

  login() {
    this.submitted = true;
    this.isDisabled = true;
    this.loginFormErr = {
      username: "",
      password: "",
    };
    if (!this.loginForm.valid) {
      console.log("Form is invalid.");
      this.isDisabled = false;
    } else {
      if (this.remeberme) {
        if (
          this.lg.username && this.lg.password
        ) {
          this.cookie.set("_mgt", btoa(this.lg.username));
          this.cookie.set("_mgm", btoa(this.lg.password));
        } else {
          this.cookie.set("_mgt", "");
          this.cookie.set("_mgm", "");
        }
      }
      let body = {
        username: this.lg.username,
        password: this.lg.password,
        redirect: btoa(window.location.pathname),
        login: 1,
        rdir: btoa(window.location.pathname),
        utm_source: "",
        s: this.package ? this.package : 'no',
      };
      this._userService.authenticate(JSON.stringify(body)).subscribe(
        (resp: any) => {
          if (this._global.getVal(resp, "code") == "200") {
            if (this._global.getVal(resp, "message") != "success") {
              let errorMessage = this._global.getVal(resp, "data");
              if (errorMessage.username != "") {
                this.loginFormErr.username = errorMessage.username;
              }
              if (errorMessage.password != "") {
                this.loginFormErr.password = errorMessage.password;
              }
              this.isDisabled = false;
            } else {
              console.log("Login Response: ", resp.data);
              let logindata = this._global.getVal(resp, "data");
              let cp: any = {
                "Identity": this._global.getVal(logindata, 'userid'),
                "Name": this._global.getVal(logindata, 'display_name'),
                "Email": this._global.getVal(logindata, 'email'),
                "MSG-whatsapp": true
              }
              if (this._global.getVal(logindata, 'user_exp')) {
                cp['user_exp'] = this._global.getVal(logindata, 'user_exp');
                let expiryDate = new Date(cp['user_exp']);
                cp['user_exp_date'] = expiryDate
                console.log('eeee: ', expiryDate.toLocaleString());
              }
              if (this._global.getVal(logindata, 'phone')) {
                if (this._global.getVal(logindata, 'phone').indexOf("+") != -1) {
                  cp['Phone'] = this._global.getVal(logindata, 'phone');
                } else {
                  if (this._global.getVal(logindata, 'country_code')) {
                    cp['Phone'] = '+' + this._global.getVal(logindata, 'country_code') + this._global.getVal(logindata, 'phone');
                  } else {
                    cp['Phone'] = '+91' + this._global.getVal(logindata, 'country_code') + this._global.getVal(logindata, 'phone');
                  }
                }
              }
              clevertap.onUserLogin.push({
                "Site": cp
              });
              if (logindata.message == 'Lockin') {
                this.openForgotPassModal(this.lockinModal, 'lockinModal');
                this.isDisabled = false;
                return;
              }
              this._global.LOGIN_USER_DETAIL = logindata;
              this.isDisabled = false;
              window.location.reload();
            }
          } else {
            console.log("Failed Response: ", resp.data);
          }
        },
      );
    }
  }

  openForgotPassModal(content: any, className: string) {
    this.forgotForm.reset();
    this.forgotStep = 'emailVerify'
    this.modalService.open(content, {
      size: "md",
      centered: true,
      backdrop: "static",
      windowClass: className + "-modal",
    });
  }

  forgotPassword() {
    this.forgotSubmitted = true;
    this.forgotEmailErr = "";
    if (!this.forgotForm.valid) {
      console.log("Forgot Form Invalid.");
    } else {
      let formVal = this.forgotForm.value;
      console.log("Form Value", formVal);
      console.log("Email Val: ", formVal.email);
      let body = {
        login_id: formVal.email,
        redirect: "/mojofeed",
      };
      this._userService.resetPassword(JSON.stringify(body)).subscribe(
        (resp: any) => {
          if (this._global.getVal(resp, "message") != "error") {
            this.forgotStep = "resetEmailSent";
          } else {
            this.forgotEmailErr = this._global.getVal(resp, "data.email");
          }
        },
      );
    }
  }

  redirectGoogle(url: any) {
    let packageVal = this.package ? this.package : 'no';
    let redirectPath = window.location.pathname;
    if (this._global.getDevicep() == "desktop" || this._global.getDevicep() == "mobile") {
      window.location.href = url + "?s=" + packageVal + '&redirect=' + redirectPath;
    } else {
      if (this._global.getAppType() == "androidapp") {
        window.location.href = url + "?s=" + packageVal + '&redirect=' + redirectPath;;
      }
      if (this._global.getAppType() == "iosapp" && this._global.getAppVersion() == 'iosv3.1') {
        (<any>window).webkit.messageHandlers.onmessage.postMessage({
          type: 'googleLogin',
        });
      }
      if (this._global.getAppType() == "iosapp" && this._global.getAppVersion() != "iosv3.1") {
        let newUrl = environment.MOJO_URL + "mojo/login?" + "ga=1&s=" + packageVal + '&redirect=' + redirectPath;;
        this.deviceredirect(newUrl);
      }
    }
  }

  redirectFacebook(url: any) {
    let packageVal = this.package ? this.package : 'no';
    let redirectPath = window.location.pathname;
    if (this._global.getDevicep() == "desktop" || this._global.getDevicep() == "mobile") {
      window.location.href = url + "?s=" + packageVal + '&redirect=' + redirectPath;
    } else {
      if (this._global.getAppType() == "iosapp" && this._global.getAppVersion() == "iosv3.1") {
        (<any>window).webkit.messageHandlers.onmessage.postMessage({
          type: 'facebookLogin',
        });
      } else if (this._global.getAppType() == "androidapp" && this._global.getAppVersion() == "androidv3.1") {
        this.sendMessageToAndroid(btoa(JSON.stringify({ type: 'facebookLogin' })));
        // window.location.href = "https://openlink.com/?url=" + btoa(url + "?s=" + packageVal);
        // (<any>window).postMessage(btoa(JSON.stringify({
        //   type: 'facebookLogin',
        // })));
      } else {
        let newUrl = environment.MOJO_URL + "mojo/login?" + "fb=1&s=" + packageVal + '&redirect=' + redirectPath;;
        this.deviceredirect(newUrl);
      }
    }
  }

  sendMessageToAndroid(message: string) {
    if (typeof Android !== 'undefined' && Android.postMessage) {
      Android.postMessage(message);
    } else {
      console.error('Android.postMessage is not available.');
    }
  }

  deviceredirect(
    url: any,
    key1 = "",
    value1 = "",
    key2 = "",
    value2 = "",
    key3 = "",
    value3 = "",
  ) {
    if (this._global.getAppType() == "iosapp") {
      (<any>window).webkit.messageHandlers.onmessage.postMessage({
        type: "openLink",
        value: url + key1 + value1 + key2 + value2 + key3 + value3,
      });
    } else if (this._global.getAppType() == "androidapp") {
      this.sendMessageToAndroid(btoa(JSON.stringify({ type: 'openLink', value: url + key1 + value1 + key2 + value2 + key3 + value3 })));
    } else {
      var newurl = url + key1 + value1 + key2 + value2 + key3 + value3;
      window.open(newurl, "_blank");
    }
  }

  appleLogin() {
    (<any>window).webkit.messageHandlers.onmessage.postMessage({
      type: "appleLogin",
    });
  }
  loadpageText(text: any = '') {
    this._userService.getSectionData({ text: text }).subscribe(
      (resp: any) => {
        if (this._global.getVal(resp, "code") == "200") {
          this.ltexes = this._global.getVal(resp, 'data', {});
        }
      });
  }

  isDisclaimerChecked(event: any) {
    console.log(event.currentTarget.checked);
    if (event.currentTarget.checked) {
      this.isChecked = true;
      this.isNotChecked = false;
    } else {
      this.isChecked = false
    }

  }

  onChange() {
    this.isUserExist = false;
  }

  register() {
    this.register_error = {};
    console.log('Is Checked: ', this.isChecked);
    this.submitted = true;
    this.isDisabled = true;
    this.isUserExist = false;
    this.isPasswordShort = false;
    this.isNotChecked = false;
    if (!this.registerForm.valid) {
      console.log("Form is invalid.");
      this.isDisabled = false;
      return;
    } else {
      let formVal = this.registerForm.value;
      console.log(formVal);
      if (formVal.password.length < 8) {
        this.isPasswordShort = true;
        this.isDisabled = false;
        return;
      }
      if (!this.isChecked) {
        this.isNotChecked = true;
        this.isPasswordShort = false;
        this.isDisabled = false;
        return;
      }
      this._userService.checkUserExist({ email: btoa(formVal.email) })
        .subscribe((resp: any) => {
          if (this._global.getVal(resp, "code") == "200") {
            let data = this._global.getVal(resp, "data");
            console.log("User Exists: ", data);
            if (this._global.getVal(data, "custom") == 1 || this._global.getVal(data, "fbgoogle") == 1 || this._global.getVal(data, "facebook") == 1 || this._global.getVal(data, "google") == 1) {
              this.isUserExist = true;
              this.isDisabled = false;
            } else {
              this.createNewUser(formVal);
              this.isUserExist = false;
              this.isPasswordShort = false;
              this.isNotChecked = false;
            }
          } else {
            this.register_error['error'] = this._global.getVal(resp, "data")
          }
        });

    }
  }

  createNewUser(formData: any) {
    this.register_error = {};
    let utype = "";
    if (this.package == "free") {
      utype = "freeuser";
    } else {
      utype = "paid";
    }
    let body = {
      email: formData.email,
      password: formData.password,
      confirm_password: formData.password,
      sp: this.package ? this.package : 'no',
      username: "",
      mobile: "",
      token: null,
      register: "y",
      captcha: true,
      rdir: "",
      bseurl: null,
      frompage: window.location.pathname,
      utype: utype,
      pn: this.pn,
      o: "",
      uid: "",
      nugs: "",
      flg: "",
      vserve: "",
      sid: "",
      sqty: "",
    };
    this._userService.easySignup(JSON.stringify(body)).subscribe(
      (resp: any) => {
        let data = this._global.getVal(resp, "data");
        if (this._global.getVal(resp, "code") == "200") {
          if (!this.is_clmm) {
            clevertap.onUserLogin.push({
              "Site": {
                "Identity": this._global.getVal(data, 'uid'),
                "Email": this._global.getVal(data, 'email'),
                "MSG-whatsapp": true
              }
            });
          }
          let queryParams: any = {
            package: this.encodePackage,
            reg: 1,
            fe: btoa(formData.email),
            redirect: window.location.pathname,
          };





          if (this.redirectFromMarketPage != 'mojofeed') {
            let queryParam = this.queryParamForMarket['pn'];
            queryParams = { ...queryParams, ...queryParam }
            const queryString = Object.keys(queryParams)
              .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
              .join('&');
            console.log('Query Param: ', queryString);
            window.location.href = `/mojofeed/mobverify?${queryString}`;
          } else {
            const queryString = Object.keys(queryParams)
              .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
              .join('&');
            console.log('Query Param: ', queryString);
            window.location.href = `/mojofeed/mobverify?${queryString}`;
          }

          this.isDisabled = false;
        } else {
          this.register_error['error'] = this._global.getVal(resp, "data")
          console.log("Registered Failed: ", data);
          this.isDisabled = false;
        }
      },
    );
  }

  changeForm(type: string) {
    if (type != this.formType) {
      this.formType = type;
      this.loginForm.reset();
      this.registerForm.reset();
      this.loginFormErr = { username: '', password: '' };
      this.isUserExist = false;
      this.register_error = {};
      this.isNotChecked = false;
      this.submitted = false;
      this.isDisabled = false;
    }
    if (this.formType == 'register') {
      this.isRegisterReadonly = true;
      setTimeout(() => {
        this.isRegisterReadonly = false;
      }, 200)
    } else {
      this.isRegisterReadonly = false;
    }
  }
}
