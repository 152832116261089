
<app-header *ngIf="show_header=='nop'" ></app-header> 
<app-common-header *ngIf="show_header=='show'" [pagename]="page" [usermsg]="card_data" [loginuser]="login_response"
  [cartCount]="'0'">
</app-common-header>
<ng-template [ngIf]="show_header=='nop' && !isLogin">
  <div id="g_id_onload"
       data-client_id="137993115107-nnpbapbgbcluj90oa5gln29125sgfm6a"
       data-context="signin"
       data-cancel_on_tap_outside="false"
       data-callback="authSocialSignin"
       data-use_fedcm_for_prompt="false"
       data-itp_support="true">
  </div>
</ng-template>
<main id="mm-apage-section"
  class="mojostock_new_landing_page_content device_{{device_type}} {{show_header=='show'?'':'page-no-header'}} app_device_{{device}}  device_app_ver_{{device_app_ver}} {{page+'_page_content'|common:'rmspace'}} {{''|common:'mainclass'}} {{apaid?'page-paid-user':'page-nonpaid-user'}} {{isLogin?'page-login':'page-non-login'}} {{ isTopNewsMsgLoaded && topNewMsg.length == 0 && page == 'strategy' ? 'no_news' : '' }}">
 <div *ngIf="header_load; else pageloadtemp"> 
    <router-outlet (activate)="onActive()"></router-outlet>
   </div>
  <ng-template #pageloadtemp>
    <app-pageloader></app-pageloader>
  </ng-template> 
</main>
<app-footer [footer_show]="footer_show" [isLogin]="isLogin" [deviceType]="device"></app-footer>
<input type="hidden" class="d-block" value="{{userMsg}}" id="user_welcome_msg" />
<input type="hidden" class="d-block" value="{{userLoginMsg}}" id="user_login_msg" />