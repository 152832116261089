<footer id="footer-new" >
    <div class="container-fluid">
       <!--  <div class="text-center">
            <ul class="stock-list">
                <li>Stock List</li>
                <li *ngFor="let s of stock_list"> <a [routerLink]="['/stocks/getstocksbyname']" [queryParams]="{ n: s}" routerLinkActive="active" class="stock-name" target="_blank" title="{{s}}">{{s}}</a> </li>
            </ul>
        </div> -->
        <div *ngIf="!is_clmm"  class="row foot-details-box">
            <div class="col-md-3 pt-3">
                <div class="py-3">
                    <img src="https://i.marketsmojo.com/bottom-logo.png">
                </div>
                <nav class="nav foot-menu">
                    <a class="nav-link col-5" href="{{frontend_url}}mojo/career">Careers</a>
                    <a class="nav-link col-7" href="{{frontend_url}}mojo/disclaimer">Disclaimer</a>
                     <a class="nav-link col-5" href="{{frontend_url}}mojo/feedback">Contact Us</a> 
                    <a class="nav-link col-7" href="{{frontend_url}}mojo/termsofuse">Terms and Conditions</a>
                    <a class="nav-link col-5" href="{{frontend_url}}mojo/refundpolicy">Refund Policy</a>
                    <a class="nav-link col-7" href="{{frontend_url}}mojo/privacypolicy">Privacy Policy</a>
                    <a class="nav-link col-5" href="{{frontend_url}}mojo/faq">FAQ</a>
                   <a class="nav-link col-7" href="{{frontend_url}}mojo/processdisclosure">Process Disclosure</a> 
                 <!--   <a class="nav-link col-5" href="{{frontend_url}}mojo/complaints">Regulatory Information</a> -->
                   <a class="nav-link col-5" *ngIf="isLogin && deviceType=='iosapp'" href="{{frontend_url}}mojopro/delete-account">Account Deletion</a>
                </nav>
                <div class="mail-box py-4">
                    <div>
                        <input type="text" id="" [(ngModel)]="email"
                        class="form-control" placeholder="abc@xyz.com">

                        <p class="error-msg">{{error_msg}}</p>
												<p >
													{{success_msg}}
												</p>
                    </div>
                    <div class="ms-2">
                        <button type="submit" (click)="subscribe(email)" class="btn send-btn">Subscribe</button>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row socailmedia-box">
                    <div class="col-3 r-bdr">
                        <a (click)="appOpenLink('https://www.facebook.com/marketsmojocom-819692454796606/')" nohref="" class="socailmedia-icon">
                            <i class="fa fa-facebook"></i>
                            <div>Facebook</div>
                        </a>
                    </div>
                    <div class="col-3 r-bdr">
                        <a (click)="appOpenLink('https://twitter.com/Marketsmojo')" nohref="" class="socailmedia-icon">
                            <i class="fa fa-twitter"></i>
                            <div>Twitter</div>
                        </a>
                    </div>
                    <div class="col-3 r-bdr">
                        <a (click)="appOpenLink('https://www.linkedin.com/company/marketsmojo/')" nohref="" class="socailmedia-icon">
                            <i class="fa fa-linkedin"></i>
                            <div>Linked in</div>
                        </a>
                    </div>
                    <div class="col-3">
                        <a (click)="appOpenLink('mailto:Support@marketsmojo.com','_self')" nohref="" class="socailmedia-icon">
                            <i class="fa fa-envelope "></i>
                            <div>Email Us</div>
                        </a>
                    </div>
                </div>

                
                <div class="Partnership-box">
                    <h6>We Power Research and Services of-</h6>
                    <div class="slider">
                    <swiper
                    [config]="config"
                    [autoplay]="{
                        delay: 2500,
                        disableOnInteraction: false
                      }"
                      [navigation]="true"
                      [breakpoints]="{
                        '640': {
                          slidesPerView: 2,
                          spaceBetween: 20
                        },
                        '428': {
                            slidesPerView: 2,
                            spaceBetween: 20
                          },
                        '768': {
                          slidesPerView: 2,
                          spaceBetween: 40
                        },
                        '1024': {
                          slidesPerView: 4,
                          spaceBetween: 50
                        }
                      }"
                  >
                    <ng-template swiperSlide data-swiper-autoplay="2000" *ngFor="let image of slider_image"><img src="https://i.marketsmojo.com/{{image.img_path}}"
                        class="foot-logo" /></ng-template>
                  </swiper>
                    </div>
										
                    <p class="fs-14 mb-0">For Business Partnership, pls write to us at <a (click)="appOpenLink('https://mail.google.com/mail/?view=cm&fs=1&to=partnership@marketsmojo.com')"
                       >partnership@marketsmojo.com</a></p>
                </div>
            </div>
            <div class="col-md-3 apps-box">
                    <h6 class="heading">GET THE MARKETSMOJO APP</h6>
                    <div class="app-link mb-2">
                        <a (click)="appOpenLink('https://play.google.com/store/apps/details?id=com.Markets.Mojo&amp;hl=en')" nohref="">
                        <img src="{{img_url}}mailer/google_app_footer.svg"></a>
                        <a (click)="appOpenLink('https://apps.apple.com/in/app/markets-mojo/id1192962208')" nohref="">
                        <img src="{{img_url}}mailer/ios_app_footer.svg"></a>
                    </div>
            </div>
        </div>
        <div  *ngIf="is_clmm" class="row foot-details-box clmm_footer">
            <div class="col-md-12 pt-3">
               
                <nav class="nav foot-menu">
                    <a class="nav-link "  href="https://www.integratedindia.in/Career.aspx" target="blank">Careers</a>
                    <a class="nav-link" href="https://www.integratedindia.in/ContactUs.aspx" target="blank">Contact Us</a>
                    <a class="nav-link " href="https://www.integratedindia.in/Disclaimer.aspx" target="blank">Disclaimer</a>					
                    <a class="nav-link " href="https://www.integratedindia.in/Policy.aspx" target="blank">Privacy Policy</a>
                    <a class="nav-link" href="https://www.integratedindia.in/Downloads/Termsandconditions.pdf" target="blank">Terms and Conditions</a>
                </nav>
               
            </div>
          
            
        </div>
        <div class="copyrightcontent2" *ngIf="!is_clmm">
            <div class="row">
                <div class="col-md-12">
                    <!-- <p class="sebitext text-center" style="margin-bottom:4px;"><b> Name:</b> Mojo Markets Pvt Ltd (Investment Advisor)  (INA000012528) (CIN: U67190MH2022PTC385279) (www.marketsmojo.com) GST NO.27AAJCM7871R1ZD​</p> -->
                    <p class="sebitext text-center" style="margin-bottom:4px;"><b> Name:</b> InfinHub Market
                        Technologies FZ-LLC </p>
                    <!-- <p class="sebitext text-center" style="margin-bottom:4px;">
                <b>Address: </b><i class="fa fa-map-marker"></i> Benefice Business House, Office No. 3B, 3rd Floor, C.S. 242, Mathuradas Mills Estate, N.M. Joshi Marg, Tulsi Pipe Road, Lower Parel West, Mumbai- 400013 <a href="https://goo.gl/maps/i11XxWzgSJ4oTVih8" target="blank" style="color:#416c9e;">locate us on maps</a>
					</p> -->
                    <p class="sebitext text-center" style="margin-bottom:4px;">
                        <b>Address: </b><i class="fa fa-map-marker"></i> InfinHub Market Technologies FZ-LLC,
						Building-2, Unit 112,  Dubai Internet City, Dubai, United Arab Emirates
                    </p>
                    <p class="sebitext text-center" style="margin-bottom:4px;"><b>Email:</b> <a
                        (click)="appOpenLink('mailto:support@marketsmojo.com','_self')">support@marketsmojo.com</a>​​</p>
                    <p class="sebitext text-center" style="margin-bottom:4px;"><b>E-mail :</b> <a
                        (click)="appOpenLink('mailto:compliance@marketsmojo.com','_self')">compliance@marketsmojo.com​</a> ​</p>
                    <!-- <p class="sebitext text-center" style="margin-bottom:4px;">Corresponding SEBI Address: Investment Management Department, CIS Division, SEBI Bhavan, Plot No.C4-"A", "G" Block, Bandra Kurla Complex, Bandra (E), Mumbai – 400051​​</p> -->

                    <p class="sebitext text-center" style="margin-bottom:4px;">
                        “Investment in securities market are subject to market risks. Read all the related documents
                        carefully before investing.”
                        <!-- “Registration granted by SEBI, membership of BASL (in case of IAs)  and  certification  from  NISM  in  no  way  guarantee  performance  of  the intermediary or provide any assurance of returns to investors” 
                    “The securities quoted are for illustration only and are not recommendatory" -->
                    </p>
                </div>
                <!-- <div class="col-md-12">
					<p class="sebitext text-center" style="margin-bottom:4px;">SEBI Registered Investment Advisor Registration No: INA000012528</p>					
					<p class="sebitext text-center" style="margin-bottom:4px;"><b>Principal Officer:</b> Mr.Karan Batra, Phone : 022 – 35121238 E-mail : <a href="mailto:compliance@marketsmojo.com" style="color:#416c9e;">compliance@marketsmojo.com</a></p>
					<p class="sebitext text-center" style="margin-bottom:4px;">
						<i class="fa fa-map-marker"></i> Benefice Business House, Office No. 3B, 3rd Floor, C.S. 242, Mathuradas Mills Estate, N.M. Joshi Marg, Tulsi Pipe Road, Lower Parel West, Mumbai- 400013 <a href="https://goo.gl/maps/i11XxWzgSJ4oTVih8" target="blank" style="color:#416c9e;">locate us on maps</a>
					</p>
				</div>	 -->
            </div>
        </div>
        <div class="copyrightcontent2" *ngIf="is_clmm">
            <div class="row">
                <div class="col-md-12">
                    <!-- <p class="sebitext text-center" style="margin-bottom:4px;"><b> Name:</b> Mojo Markets Pvt Ltd (Investment Advisor)  (INA000012528) (CIN: U67190MH2022PTC385279) (www.marketsmojo.com) GST NO.27AAJCM7871R1ZD​</p> -->
                    <p class="sebitext text-center" style="margin-bottom:4px;"><b> Name:</b> Integrated Enterprises (India) Private Limited</p>
                    <!-- <p class="sebitext text-center" style="margin-bottom:4px;">
                <b>Address: </b><i class="fa fa-map-marker"></i> Benefice Business House, Office No. 3B, 3rd Floor, C.S. 242, Mathuradas Mills Estate, N.M. Joshi Marg, Tulsi Pipe Road, Lower Parel West, Mumbai- 400013 <a href="https://goo.gl/maps/i11XxWzgSJ4oTVih8" target="blank" style="color:#416c9e;">locate us on maps</a>
					</p> -->
                    <p class="sebitext text-center" style="margin-bottom:4px;">
                        <b>Address: </b><i class="fa fa-map-marker"></i> 501-502, Coral Classic Commercial Complex, Road No. 20,Chembur East,Mumbai - 400 071
                    </p>
                    <p class="sebitext text-center" style="margin-bottom:4px;"><b>Email:</b> <a
                            href="mailto:customercare@integratedindia.in">customercare@integratedindia.in</a>​​</p>
                  
                    <!-- <p class="sebitext text-center" style="margin-bottom:4px;">Corresponding SEBI Address: Investment Management Department, CIS Division, SEBI Bhavan, Plot No.C4-"A", "G" Block, Bandra Kurla Complex, Bandra (E), Mumbai – 400051​​</p> -->

                    <p class="sebitext text-center" style="margin-bottom:4px;">
                        “Investment in securities market are subject to market risks. Read all the related documents
                        carefully before investing.”
                        <!-- “Registration granted by SEBI, membership of BASL (in case of IAs)  and  certification  from  NISM  in  no  way  guarantee  performance  of  the intermediary or provide any assurance of returns to investors” 
                    “The securities quoted are for illustration only and are not recommendatory" -->
                    </p>
                </div>
                <!-- <div class="col-md-12">
					<p class="sebitext text-center" style="margin-bottom:4px;">SEBI Registered Investment Advisor Registration No: INA000012528</p>					
					<p class="sebitext text-center" style="margin-bottom:4px;"><b>Principal Officer:</b> Mr.Karan Batra, Phone : 022 – 35121238 E-mail : <a href="mailto:compliance@marketsmojo.com" style="color:#416c9e;">compliance@marketsmojo.com</a></p>
					<p class="sebitext text-center" style="margin-bottom:4px;">
						<i class="fa fa-map-marker"></i> Benefice Business House, Office No. 3B, 3rd Floor, C.S. 242, Mathuradas Mills Estate, N.M. Joshi Marg, Tulsi Pipe Road, Lower Parel West, Mumbai- 400013 <a href="https://goo.gl/maps/i11XxWzgSJ4oTVih8" target="blank" style="color:#416c9e;">locate us on maps</a>
					</p>
				</div>	 -->
            </div>
        </div>
        <div class="copyrightcontent" *ngIf="is_clmm">
            <div class="row">
                <div class="col-md-12">
                    <div class="copyrighttext text-center">
                        Copyright Integrated Enterprises (India) Private Ltd., All rights reserved. Reproduction of any articles, photos, videos or any other content in whole or in part in any form or medium without express written permission of integratedinvestments.in is prohibited.
                    </div>
                </div>
            </div>
        </div>
        <div class="copyrightcontent" *ngIf="!is_clmm">
			<div class="row">				
				<div class="col-md-12">
					<div class="copyrighttext text-center">
						Copyright © {{cyear}} Markets Mojo
					</div>
				</div>				
			</div>
		</div>
        <!-- <div class="d-md-flex py-3 cpr-text">
            <div>SEBI Registered Investment Advisor Registration No: INA000012528</div>
            <div class="ms-auto">Copyright © {{cyear}} Markets Mojo</div>
        </div> -->
    </div>
</footer>
<app-toast></app-toast>
<ng-template #loginModal let-loginModal>
    <app-login-modal [modal]="loginModal"></app-login-modal>
 </ng-template>