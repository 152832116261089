import { Injectable } from '@angular/core';
import { GlobalService } from '@common/global';
import { ApiService } from '@services/api.service';
import { environment } from '@env/environment';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private _global: GlobalService, private api: ApiService) { }
  usertrackingdata(obj:any={}){
    return this.api.call(environment.FRAPI_URL + 'stocks/usertrackingdata' , obj);
  }

  resetpassword(){
    return this.api.getCall(environment.MOJO_URL +'mojo/resetpassword');
  }
}
