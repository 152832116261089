<div class="exitcall-closebtn">
  <i class="bi bi-x-circle-fill" (click)="modal.close('Cross click')"></i>
</div>
<section class="vh-25">
  <div class=" new-feed-body-bg">
    <div class="container new-feed-login-screen">
      <div class="row">
        <div class="col-12 col-md-6 marginb-4">
          <h1 class="text-start" [innerHTML]="ltexes?.heading"></h1>
          <!--  <div class="text-start sub-text my-3" [innerHTML]="ltexes?.body"></div>  -->
          <div class="non-login-banner"><img src="{{ sfeedImgUrl }}{{ltexes?.image}}" class="img-fluid">
          </div>
        </div>
        <div class="col-12 col-md-6 feed-login-box">
          <div class="feed-login-card">
            <ul class="floating-menu-user">
              <li><a nohref="#" (click)="changeForm('register')" class="{{ formType == 'register' ? 'active' : ''}}">New
                  User </a></li>
              <li><a nohref="#" (click)="formType = 'login'" class="{{ formType == 'login' ? 'active' : ''}}">Existing
                  User</a></li>
            </ul>
            <div class="text-center feed-new-login-message ">
              <span *ngIf="formType == 'login'">Login and Continue</span>
              <span *ngIf="formType == 'register'">Sign Up and Explore our services</span>
            </div>
            <div class="new-feed-login-form">
              <ng-template [ngIf]="formType == 'login'">
                <form [formGroup]="loginForm" (ngSubmit)="login()">
                  <div class="marginb-3">
                    <input type="text" class="form-control" d="username" formControlName="email"
                      [(ngModel)]="lg.username" placeholder="Email Address">
                    <div *ngIf="submitted && (formVal['email'].errors && !loginFormErr.username)" id="emailHelp"
                      class="form-text text-danger">
                      <div *ngIf="formVal['email'].errors['required']">
                        Username is required
                      </div>
                      <div *ngIf="formVal['email'].errors['email']">
                        Invalid Email
                      </div>
                    </div>
                    <div *ngIf="submitted && loginFormErr.username != ''" id="emailHelp" class="form-text text-danger">
                      {{ loginFormErr.username }}
                    </div>
                    <!--  <div id="emailHelp" class="form-text text-danger">Please Enter Email ID</div> -->
                  </div>
                  <div class="marginb-3">
                    <!-- <label for="exampleInputPassword1" class="form-label">Password</label> -->
                    <input type="password" formControlName="password" [(ngModel)]="lg.password" class="form-control"
                      id="exampleInputPassword1" placeholder="Enter Password">

                    <div *ngIf="submitted && (formVal['password'].errors && !loginFormErr.password)" id="emailHelp"
                      class="form-text text-danger">
                      <div *ngIf="formVal['password'].errors['required']">
                        Password is required
                      </div>
                      <div *ngIf="formVal['password'].errors['pattern']">
                        Your password must contain one letter and number, and
                        atleast 8 or more characters.
                      </div>
                    </div>
                    <div *ngIf="submitted && loginFormErr.password != ''" id="emailHelp" class="form-text text-danger">
                      {{ loginFormErr.password }}
                    </div>

                  </div>
                  <div class="d-flex marginb-3 new-feed-login-re">
                    <div class="">
                      <div class="marginb-3 form-check">
                        <input type="checkbox" class="form-check-input" formControlName="remeberme" id="exampleCheck1"
                          [(ngModel)]="remeberme" (change)="checkedRememberMe()">
                        <label class="form-check-label" for="exampleCheck1">Remember me</label>
                      </div>
                    </div>
                    <div class="ms-auto forgot-password"><a nohref=""
                        (click)="openForgotPassModal(forgotPass, 'forgotPass')">Forgot Password?</a></div>
                  </div>

                  <button type="submit" class="btn login-btn" [disabled]="isDisabled">
                    {{ isDisabled ? '...Wait' : 'Login' }}</button>
                </form>
              </ng-template>
              <ng-template [ngIf]="formType == 'register'">
                <form [formGroup]="registerForm" (ngSubmit)="register()">
                  <div class="marginb-3">
                    <!-- <label for="exampleInputEmail1" class="form-label text-start">Email address</label> -->
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                      placeholder="Email Address" formControlName="email" (keyup)="onChange()" [readonly]="isRegisterReadonly" />
                    <div *ngIf="submitted && (registerFormVal['email'].errors && !isUserExist)" id="emailHelp"
                      class="form-text text-danger">
                      <div *ngIf="registerFormVal['email'].errors['required']">
                        Username is required
                      </div>
                      <div *ngIf="registerFormVal['email'].errors['email']">
                        Invalid Email
                      </div>
                    </div>
                    <div *ngIf="isUserExist && !registerFormVal['email'].errors" id="emailHelp" class="form-text text-danger">
                      User already exists.
                    </div>
                  </div>
                  <div class="marginb-3">
                    <!-- <label for="exampleInputPassword1" class="form-label">Password</label> -->
                    <input type="password" class="form-control" id="password" formControlName="password"
                      placeholder="Enter Password" [readonly]="isRegisterReadonly">

                    <div *ngIf="submitted && (registerFormVal['password'].errors && !isPasswordShort)" id="emailHelp"
                      class="form-text text-danger">
                      <div *ngIf="registerFormVal['password'].errors['required']">
                        Password is required
                      </div>
                    </div>
                    <div *ngIf="isPasswordShort" id="emailHelp" class="form-text text-danger">
                      Password must be 8 characters long.
                    </div>

                  </div>
                  <div class="marginb-3 form-check">
                    <input type="checkbox" class="form-check-input" (change)="isDisclaimerChecked($event)"
                      id="exampleCheck1">
                    <label class="form-check-label policy" for="exampleCheck1">By signing up, you have read and agreed
                      to MarketsMojo's <a href="/mojofeed/disclaimer?new=1" [target]="targetVal">TnC</a> , <a
                        href="/mojo/privacypolicy" [target]="targetVal">Privacy Policy</a> and <a
                        href="/mojo/disclaimer" [target]="targetVal">Disclaimer</a></label>
                  </div>
                  <div *ngIf="isNotChecked" class="invalid-error">
                    Kindly accept terms and condition to proceed.
                  </div>
                  <div *ngIf="register_error?.error" class="invalid-error">
                    {{register_error?.error}}
                  </div>
                  <button type="submit" class="btn login-btn" [disabled]="isDisabled">
                    {{ isDisabled ? '...Wait' : 'Sign Up' }}</button>
                </form>
              </ng-template>
              <p class="text-center marginy-2 or">or</p>
              <div class="new-feed-social-media text-center margint-3">
                <h3 class="">Continue With</h3>
                <div class="d-flex flex-row marginb-3 justify-content-center">
                  <div class="p-2"><a (click)="redirectGoogle(logoutDetail.ga_login)" nohref="#"><img
                        src="{{ sfeedImgUrl }}mm-g-icon.png" class="img-fluid"></a></div>
                  <div class="p-2"><a (click)="redirectFacebook(logoutDetail.fb_login)" nohref="#"><img
                        src="{{ sfeedImgUrl }}mm-f-icon.png" class="img-fluid"></a></div>
                  <div class="p-2" *ngIf="appType == 'iosapp'"><a (click)="appleLogin()" nohref="#"><img
                        src="{{ sfeedImgUrl }}mm-i-icon.png" class="img-fluid"></a></div>
                  <!--  <div class="p-2"><a href="#"><img src="{{ sfeedImgUrl }}mm-app-icon.svg" class="img-fluid"></a></div> -->
                </div>
              </div>
              <ng-template [ngIf]="formType == 'login'">
                <p class="feed-a-info text-center">Don't have an Account. <a nohref="#"
                    (click)="formType = 'register'">Create here</a></p>
              </ng-template>
              <ng-template [ngIf]="formType == 'register'">
                <p class="feed-a-info text-center"><a nohref="#" (click)="formType = 'login'">Already have an
                    account?</a></p>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #forgotPass let-forgotPass>
  <div class="modal-body">
    <ng-template [ngIf]="forgotStep == 'emailVerify'">
      <form [formGroup]="forgotForm">
        <div class="row">
          <div class="col-md-12 feed-card marginb-4 p-0">
            <div class="card card-margin shadow-none p-0 px-4">
              <div class="d-flex justify-content-end text-end">
                <button type="button" class="btn btn-link"
                  (click)="forgotForm.reset();forgotPass.close('close dialog');">
                  <i class="bi bi-x-circle new-forgot-btn-close"></i>
                </button>
              </div>
              <h4 class="new-forgot-pass-head">Forgot Password?</h4>
              <p class="new-forgot-pass-ms marginb-4">Enter your registered email address & we’ll mail you the password reset
                link.</p>
              <div class="input-group marginb-3 new-forgot-pass new-forgot-box-shadow new-forgot-box">
                <span class="new-forgot-pass-border border-end-0" id="basic-addon1">
                  <img src="{{ sfeedImgUrl }}mail-forgot-icon.png" class="img-fluid me-2">
                </span>
                <input type="text" formControlName="email" class="form-control new-forgot-pass-border border-start-0"
                  placeholder="Email Address" aria-label="Username" aria-describedby="basic-addon1">
              </div>
              <div class="row margint-2">
                <div class="col-12 col-md-12">
                  <div class="" *ngIf="forgotSubmitted && forgotFormVal['email'].errors">
                    <div *ngIf="forgotFormVal['email'].errors['required']" class="text-danger">
                      Email is required
                    </div>
                    <div *ngIf="forgotFormVal['email'].errors['email']" class="text-danger">
                      Invalid Email
                    </div>
                  </div>
                  <div class="text-danger" *ngIf="forgotEmailErr != ''">{{
                    forgotEmailErr
                    }}</div>
                </div>
              </div>
              <div class="row mt-4 text-center">
                <div class="col-12 col-md-12">
                  <button type="button" class="btn btn-sm btn-grad w-50 me-2" (click)="forgotPassword()">Reset
                    Password</button>
                  <button type="button" class="btn btn-sm add-watclist"
                    (click)="forgotForm.reset();forgotPass.close('Close dialog');">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
    <ng-template [ngIf]="forgotStep == 'resetEmailSent'">
      <div class="row">
        <div class="col-md-12 feed-card marginb-4 p-0">
          <div class="card card-margin shadow-none p-0 px-4">
            <div class="d-flex justify-content-end text-end">
              <button type="button" class="btn btn-link" (click)="forgotPass.close('close dialog')">
                <i class="bi bi-x-circle new-forgot-btn-close"></i>
              </button>
            </div>
            <h4 class="new-forgot-pass-head"><i class="fa fa-check"></i> RESET LINK SENT</h4>
            <p class="new-forgot-pass-ms marginb-4">
              Didn’t receive email? <br />
              Please check your spam folder or
              <a nohref class="pe-auto" (click)="forgotStep = 'emailVerify'">resend email</a>
            </p>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>
<ng-template #lockinModal let-lockinModal>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 feed-card marginb-4 p-0">
        <div class="card card-margin shadow-none p-0 px-4">
          <div class="d-flex justify-content-end text-end">
            <button type="button" class="btn btn-link" (click)="lockinModal.close('close dialog')">
              <i class="bi bi-x-circle new-forgot-btn-close"></i>
            </button>
          </div>
          <h4 class="new-forgot-pass-head"><i class="fa fa-check"></i> MULTIPLE DEVICE IN USE</h4>
          <p class="new-forgot-pass-ms marginb-4">
            We have sent you an alert email regarding multiple devices in use
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<!-- <form action="/mojo/modelportfolio_investment" method="POST" id="frmSelectProfile" name="frmSelectProfile"
  #frmSelectProfile>
  <input type='hidden' name="investment_amount" value="{{rdm_cookie?.amount}}" id="investment_amount" />
  <input type='hidden' name="sel_profile" value="{{rdm_cookie?.type}}" id="sel_profile" />
</form> -->